
import { prop, Vue } from "vue-class-component";

class CommandLineInputProps {
  dirPath = prop({
    default: "/",
  });
  commandChars = prop<string[]>({
    default: [],
  });
  cursorPosition = prop({
    default: 0,
  });
}

export default class CommandLineInput extends Vue.with(CommandLineInputProps) {
  private showCursor = false;

  mounted(): void {
    setInterval(this.onTick, 500);
  }

  private onTick(): void {
    this.showCursor = !this.showCursor;
  }
}
