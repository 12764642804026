
import { prop, Vue } from "vue-class-component";

import { IFile } from "@/utility/FileSystem";

import EFileType from "@/enum/EFileType";

class LongListPrintProps {
  dir = prop<IFile>({});
}

export default class LongListPrint extends Vue.with(LongListPrintProps) {
  private files: IFile[] = [];
  private sizeLength = 0;
  private EFileType = EFileType;

  created(): void {
    this.files = Object.values(this.dir?.subFiles ?? {});
    for (const file of this.files) {
      this.sizeLength =
        file.size.length > this.sizeLength ? file.size.length : this.sizeLength;
    }
  }
}
