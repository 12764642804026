
import { prop, Vue } from "vue-class-component";

class CommandLineProps {
  dirPath = prop({
    default: "/",
  });
  command = prop({
    default: "",
  });
}

export default class CommandLine extends Vue.with(CommandLineProps) {}
