
import { markRaw } from "vue";
import { prop, Vue } from "vue-class-component";

import ListPrint from "./ListPrint.vue";
import ImagePrint from "./ImagePrint.vue";
import LongListPrint from "./LongListPrint.vue";
import PlainTextPrint from "./PlainTextPrint.vue";
import NavigationPrint from "./NavigationPrint.vue";
import CommandLine from "./CommandLine/CommandLine.vue";

class ComponentPresenterProps {
  props = prop<any>({
    default: {},
  });
}

export default class ComponentPresenter extends Vue.with(
  ComponentPresenterProps
) {
  get component(): any {
    switch (this.props.component) {
      case "CommandLine":
        return markRaw(CommandLine);
      case "PlainTextPrint":
        return markRaw(PlainTextPrint);
      case "ListPrint":
        return markRaw(ListPrint);
      case "LongListPrint":
        return markRaw(LongListPrint);
      case "NavigationPrint":
        return markRaw(NavigationPrint);
      case "ImagePrint":
        return markRaw(ImagePrint);
      default:
        return undefined;
    }
  }
}
