
import { prop, Vue } from "vue-class-component";

class RecommendCommandProps {
  recommends = prop<string[]>({
    default: [],
  });
}

export default class RecommendCommand extends Vue.with(RecommendCommandProps) {
  private onClick(command: string): void {
    this.$emit("onClick", command);
  }
}
