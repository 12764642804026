import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c93365f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "recommend-command",
  class: "d-flex flex-wrap"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.recommends, (command, index) => {
      return (_openBlock(), _createElementBlock("button", {
        class: "command",
        key: index,
        onClick: ($event: any) => (this.onClick(command))
      }, _toDisplayString(">" + command), 9, _hoisted_2))
    }), 128))
  ]))
}