
import { prop, Vue } from "vue-class-component";

class ImagePrintProps {
  text = prop<string>({
    default: "",
  });
  url = prop<string>({});
}

export default class ImagePrint extends Vue.with(ImagePrintProps) {
  private loadComplete(): void {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  }
}
