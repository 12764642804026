import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5158f5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "image-print" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(this.text), 1),
    _createElementVNode("img", {
      src: this.url,
      alt: "",
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (this.loadComplete && this.loadComplete(...args)))
    }, null, 40, _hoisted_2)
  ]))
}