
import { Vue } from "vue-class-component";

export default class LoadingPrint extends Vue {
  private animation = ["/", "-", "\\", "|"];
  private index = 0;

  mounted(): void {
    setInterval(this.onTick, 100);
  }

  private onTick(): void {
    this.index = (this.index + 1) % 4;
  }
}
