
import { prop, Vue } from "vue-class-component";

import { IFile } from "@/utility/FileSystem";

import EFileType from "@/enum/EFileType";

class ListPrintProps {
  dir = prop<IFile>({});
}

export default class ListPrint extends Vue.with(ListPrintProps) {
  private files: IFile[] = [];
  private EFileType = EFileType;

  created(): void {
    this.files = Object.values(this.dir?.subFiles ?? {});
  }
}
