import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-129c343a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "list-print",
  class: "d-flex flex-wrap"
}
const _hoisted_2 = {
  key: 0,
  class: "executable"
}
const _hoisted_3 = {
  key: 1,
  class: "dir"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 3,
  class: "plain-file"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.files, (file, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "file",
        key: index
      }, [
        (file.type === _ctx.EFileType.EXECUTABLE)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(file.name), 1))
          : _createCommentVNode("", true),
        (file.type === _ctx.EFileType.DIR)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(file.name), 1))
          : _createCommentVNode("", true),
        (file.type === _ctx.EFileType.LINK)
          ? (_openBlock(), _createElementBlock("a", {
              key: 2,
              class: "link",
              href: file.value,
              target: "_blank",
              rel: "noopener noreferrer"
            }, _toDisplayString(file.name), 9, _hoisted_4))
          : _createCommentVNode("", true),
        (file.type === _ctx.EFileType.FILE || file.type === _ctx.EFileType.IMAGE)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(file.name), 1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}